// no tests https://github.com/tmpvar/jsdom/issues/1781
import _forOwn from 'lodash/forOwn';

const eventName = (function getTransitionEvent() {
    let transitionEndEvent = null;
    const el = document.createElement('fakeelement');
    const transitions = {
        transition: 'transitionend',
        OTransition: 'oTransitionEnd',
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd',
        msTransition: 'MSTransitionEnd',
    };

    // eslint-disable-next-line consistent-return
    _forOwn(transitions, (value, key, object) => {
        if (el.style[key] !== undefined) {
            transitionEndEvent = value;
            return false;
        }
    });

    return transitionEndEvent;
}());

export default eventName;
