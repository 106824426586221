import debounce from 'lodash/debounce';
import { create } from '../component/toggle-menu';

const SIZE_THRESHOLD = 16 * 39;
const MENU_DESKTOP = 'BIG';
const MENU_MOBILE = 'SMALL';
let currentWindowSize = 0;
let subMenus;

function updateMenuSize() {
    const proposedMenuSize = window.matchMedia(
        `(min-width: ${SIZE_THRESHOLD}px)`,
    ).matches
        ? MENU_DESKTOP
        : MENU_MOBILE;
    if (proposedMenuSize === currentWindowSize) {
        // no change in window size
        return;
    }
    currentWindowSize = proposedMenuSize;
    if (proposedMenuSize === MENU_DESKTOP) {
        if (subMenus && subMenus.length) {
            subMenus.forEach((x) => x.destroy());
        }
        subMenus = null;
    } else {
        subMenus = [
            create({
                buttonSelector: '.quick-launch--archive .quick-launch__toggle-btn',
                listSelector: '.quick-launch--archive .quick-launch__toggle-contents',
                onAfterOpen: () => {
                    document.querySelector(
                        '.quick-launch--archive .quick-launch__toggle-btn',
                    ).textContent = 'hide';
                },
                onAfterClose: () => {
                    document.querySelector(
                        '.quick-launch--archive .quick-launch__toggle-btn',
                    ).textContent = 'show';
                },
            }),

            create({
                buttonSelector: '.quick-launch--categories .quick-launch__toggle-btn',
                listSelector: '.quick-launch--categories .quick-launch__toggle-contents',
                onAfterOpen: () => {
                    document.querySelector(
                        '.quick-launch--categories .quick-launch__toggle-btn',
                    ).textContent = 'hide';
                },
                onAfterClose: () => {
                    document.querySelector(
                        '.quick-launch--categories .quick-launch__toggle-btn',
                    ).textContent = 'show';
                },
            }),
        ];
    }
}

function init() {
    const onWindowResize = debounce(updateMenuSize, 750);
    window.addEventListener('resize', onWindowResize);
    updateMenuSize();
}

export { init };
