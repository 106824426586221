import transitionEventName from './getTransitionEventName';

function create({
    buttonSelector,
    listSelector,
    listenToBodyClick = false,
    onOpen,
    onClose,
    onAfterOpen,
    onAfterClose,
}) {
    let currentResolve;
    const button = document.querySelector(buttonSelector);
    const list = document.querySelector(listSelector);

    const onTransitionEndEvent = (event) => {
        if (event.propertyName !== 'height') {
            return;
        }
        event.stopImmediatePropagation();
        const expanded = button.getAttribute('aria-expanded') === 'true';
        list.hidden = !expanded;
        if (expanded) {
            list.setAttribute('style', 'height: auto');
            if (onAfterOpen) {
                onAfterOpen({ button, list });
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (onAfterClose) {
                onAfterClose({ button, list });
            }
        }
        currentResolve();
    };

    function checkTransitionEnd() {
        if (transitionEventName === null) {
            onTransitionEndEvent({
                propertyName: 'height',
                stopImmediatePropagation: () => {},
            });
        }
    }

    function closeMenu() {
        return new Promise((resolve) => {
            button.setAttribute('aria-expanded', false);
            const targetHeight = list.offsetHeight;
            list.setAttribute('style', `height: ${targetHeight}px`);
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    list.setAttribute('style', 'height: 0px');
                    currentResolve = resolve;
                    checkTransitionEnd();
                    if (onClose) {
                        onClose({ button, list });
                    }
                });
            });
        });
    }

    function openMenu() {
        return new Promise((resolve) => {
            button.setAttribute('aria-expanded', true);
            list.hidden = false;
            const copy = list.cloneNode(true);
            copy.classList.add('main-nav__pages-list--for-size');
            copy.removeAttribute('style');
            button.parentNode.appendChild(copy);
            const targetHeight = copy.offsetHeight;
            button.parentNode.removeChild(copy);
            list.setAttribute('style', `height: ${targetHeight}px`);
            currentResolve = resolve;
            checkTransitionEnd();
            if (onOpen) {
                onOpen({ button, list });
            }
        });
    }

    const onEscapePressed = (event) => {
        event.preventDefault();

        const expanded = button.getAttribute('aria-expanded') === 'true';
        if (expanded) {
            event.stopImmediatePropagation();
        }
        if (event.key === 'Escape' || event.key === 'Esc') {
            closeMenu();
            button.focus();
        }
    };

    const onClicked = (event) => {
        event.preventDefault();
        const expanded = button.getAttribute('aria-expanded') === 'true';
        if (expanded) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onFocusIn = (event) => {
        if (button.getAttribute('aria-expanded') !== 'true') {
            // focused during transition
            button.focus();
        }
    };

    const onBodyClick = (event) => {
        if (!listenToBodyClick) {
            return;
        }
        const isExpanded = button.getAttribute('aria-expanded') === 'true';
        const inMenu = list.contains(event.target) || event.target === list;
        const inMenuBtn = button.contains(event.target) || event.target === button;
        if (isExpanded && !inMenu && !inMenuBtn) {
            closeMenu();
        }
    };

    function addListeners() {
        button.addEventListener('click', onClicked);
        list.addEventListener('keyup', onEscapePressed);
        button.addEventListener('keyup', onEscapePressed);
        list.addEventListener(transitionEventName, onTransitionEndEvent);
        list.addEventListener('focusin', onFocusIn);
        document.body.addEventListener('click', onBodyClick);
    }

    function destroy() {
        button.removeEventListener('click', onClicked);
        list.removeEventListener('keyup', onEscapePressed);
        button.removeEventListener('keyup', onEscapePressed);
        list.removeEventListener(transitionEventName, onTransitionEndEvent);
        list.removeEventListener('focusin', onFocusIn);
        document.body.removeEventListener('click', onBodyClick);
        button.hidden = false;
        list.hidden = false;
        list.removeAttribute('style');
        button.removeAttribute('aria-expanded');
        if (button.tagName.toLowerCase() === 'a') {
            button.removeAttribute('role');
        }
    }

    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    list.setAttribute('style', 'height: 0px');
    list.hidden = true;

    if (button.tagName.toLowerCase() === 'a') {
        button.setAttribute('role', 'button');
    }
    addListeners();

    return {
        destroy,
        button,
        list,
        openMenu,
        closeMenu,
    };
}

export { create };
